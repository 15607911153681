import "./App.scss";
import React, { useState, useCallback, lazy, Suspense, useEffect } from "react";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import { Analytics } from "@vercel/analytics/react";
import { SpeedInsights } from "@vercel/speed-insights/react";
import loadingGif from "./assets/images/ring-load.gif"; // Import the loading GIF
import musicBackground from "./assets/musics/i-mean-love.mp3";

// Lazy load components
const InvitationCard = lazy(() => import("./components/InvitationCard"));
const MainContent = lazy(() => import("./components/MainContent"));
const ModalManager = lazy(() => import("./components/ModalManager"));

const backgroundSound = new Audio(musicBackground);

function App() {
  const [modals, setModals] = useState({
    isModalItem1: false,
    isModalItem2: false,
    isModalItem3: false,
    isModalItem4: false,
    isModalItem5: false,
    isModalItem6: false,
    isModalItem7: false,
    isModalItem8: false,
  });
  const [showMainContent, setShowMainContent] = useState(false); // New state to control visibility

  useEffect(() => {
    // Disable right-click
    const disableContextMenu = (e) => e.preventDefault();
    document.addEventListener("contextmenu", disableContextMenu);

    const ctrlShiftKey = (e, keyCode) => {
      return e.ctrlKey && e.shiftKey && e.keyCode === keyCode.charCodeAt(0);
    };

    const handleKeyDown = (e) => {
      // Disable F12, Ctrl + Shift + I, Ctrl + Shift + J, Ctrl + U
      if (
        e.keyCode === 123 ||
        ctrlShiftKey(e, "I") ||
        ctrlShiftKey(e, "J") ||
        ctrlShiftKey(e, "C") ||
        (e.ctrlKey && e.keyCode === "U".charCodeAt(0))
      ) {
        e.preventDefault();
        return false;
      }
    };

    document.addEventListener("keydown", handleKeyDown);

    // Cleanup event listeners on component unmount
    return () => {
      document.removeEventListener("contextmenu", disableContextMenu);
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [])

  // Open modal
  const openModal = useCallback((item) => {
    setModals((prev) => ({ ...prev, [item]: true }));
  }, []);

  // Close modal
  const closeModal = useCallback((item) => {
    setModals((prev) => ({ ...prev, [item]: false }));
  }, []);

  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/"
          element={
            <div className="section">
              <Suspense
                fallback={
                  <div className="loading-container-suspense">
                    <img src={loadingGif} alt="Loading..." />
                  </div>
                }
              >
                <InvitationCard
                  onClosePopup={() => setShowMainContent(true)}
                  audio={backgroundSound}
                />
              </Suspense>
              {showMainContent && (
                <Suspense
                  fallback={
                    <div className="loading-container-suspense">
                      <img src={loadingGif} alt="Loading..." />
                    </div>
                  }
                >
                  <MainContent
                    setModalItem1={() => openModal("isModalItem1")}
                    setModalItem2={() => openModal("isModalItem2")}
                    setModalItem3={() => openModal("isModalItem3")}
                    setModalItem6={() => openModal("isModalItem6")}
                    setModalItem7={() => openModal("isModalItem7")}
                    setModalItem8={() => openModal("isModalItem8")}
                    backgroundSound={backgroundSound}
                  />
                  <ModalManager modals={modals} closeModal={closeModal} />
                </Suspense>
              )}
            </div>
          }
        />
      </Routes>
      <Analytics />
      <SpeedInsights />
    </BrowserRouter>
  );
}

export default App;
